import React, { useMemo } from 'react';
import MUIDataTable, { MUIDataTableOptions } from 'mui-datatables';

type DataTableMuiProps = {
  title?: string | null;
  titleFor?: string | null;
  columns: Array<any>;
  values: Array<any>;
  pagination?: boolean;
};

const DataTableMui = ({
  title,
  titleFor,
  columns,
  values,
  pagination,
}: DataTableMuiProps) => {
  const options: MUIDataTableOptions = {
    filter: true,
    sort: true,
    selectableRows: 'none',
    pagination: pagination,
    rowsPerPage: 15,
    elevation: 2,
    responsive: 'simple',
    textLabels: {
      body: {
        noMatch: 'Nenhum registro foi encontrado',
        toolTip: 'Organizar',
        columnHeaderTooltip: (column) => `Organizar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima página',
        previous: 'Página anterior',
        rowsPerPage: 'Registros por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Download CSV',
        print: 'Imprimir',
        viewColumns: 'Visualizar colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'Todos',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Mostrar colunas na tabela',
        titleAria: 'Mostrar/Esconder colunas',
      },
      selectedRows: {
        text: 'linha(s) selecionadas',
        delete: 'Deletar',
        deleteAria: 'Deletar registros selecionados',
      },
    },
  };

  const columnsMemo = useMemo(() => columns, [columns]);
  const valuesMemo = useMemo(() => values, [values]);

  return (
    <MUIDataTable
      title={titleFor ? `${title} para: ${titleFor}` : title}
      data={valuesMemo}
      columns={columnsMemo}
      options={options}
    />
  );
};

export default DataTableMui;
