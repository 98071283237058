import { Backdrop, Box, CircularProgress } from '@mui/material';

// actions
import { useLoaderScreen } from './loadingActions';

// styles
import { useStyles } from './loading-styles';

const Loading = () => {
  const { open } = useLoaderScreen();
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Box display="flex">
        <CircularProgress color="inherit" />
        {/* <Box display="flex" alignItems="center" paddingLeft="1rem">
          <Typography variant="h6">Carregando dados do usuário</Typography>
        </Box> */}
      </Box>
    </Backdrop>
  );
};

export default Loading;
