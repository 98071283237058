import { createTheme, Theme } from '@mui/material/styles';

const { typography } = createTheme();

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#2E3C7E',
    },
    secondary: {
      main: '#EEF2F5',
    },
    error: {
      main: '#C50E0E',
    },
    warning: {
      main: '#FF8B00',
    },
    background: {
      default: '#FAFAFC',
    },
    success: {
      main: '#32cc6f',
    },
    text: {
      primary: '#123952',
    },
    common: {
      white: '#ffffff',
      black: '#000000',
    },
  },
  typography: {
    fontFamily: 'Source Sans Pro, sans-serif',
    h1: {
      fontSize: typography.pxToRem(48),
    },
    h2: {
      fontSize: typography.pxToRem(40),
    },
    h3: {
      fontSize: typography.pxToRem(33),
    },
    h4: {
      fontSize: typography.pxToRem(28),
    },
    h5: {
      fontSize: typography.pxToRem(23),
    },
    h6: {
      fontSize: typography.pxToRem(19),
    },
  },
});
