import { useEffect, useState, useCallback } from 'react';
import {
  Paper,
  Button,
  Typography,
  Box,
  Grid,
  MenuItem,
  IconButton,
} from '@mui/material';
import {
  Cached,
  Image,
  CheckBoxOutlineBlank,
  CheckBox,
} from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FormProvider, useForm } from 'react-hook-form';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import Resizer from 'react-image-file-resizer';

// api
import { ApiClient } from '../../../infra';

// components
import { InputSelect, InputDatepicker } from '../../components';

// types
import {
  ImagesFormTypes,
  HotelsFormTypes,
  UnitsFormTypes,
} from '../../../domain/types';

const OfferEdit = () => {
  const { id, type } = useParams();
  const navigate = useNavigate();

  const [valuesImageEdit, setValuesToEdit] = useState<any>(null);
  const [imageOffer, setImageOffer] = useState([]);
  const [isRedirected, setIsRedirected] = useState(false);
  const [imagesType, setImagesType] = useState<Array<ImagesFormTypes>>([]);
  const [hotels, setHotels] = useState<Array<HotelsFormTypes>>([]);
  const [units, setUnits] = useState<Array<UnitsFormTypes>>([]);

  const defaultValues = {
    imageFor: type,
    unidade: '',
    imageType: '',
    hotel: '',
    checkin: '',
    checkout: '',
    qtdAdultos: '1',
    qtdCriancas: '1',
    qtdQuartos: '1',
  };
  const methods = useForm({
    defaultValues,
    shouldFocusError: true,
    mode: 'onChange',
  });

  const { handleSubmit, watch, setValue } = methods;

  const watchImageFor = watch('imageFor');
  const watchCheckIn = watch('checkin');

  // get image input value
  const onChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined,
  ) => {
    setImageOffer(imageList as never[]);
  };

  // get imagesType
  const getImagesType = useCallback(async () => {
    const response = await ApiClient.get(
      '/api/v1/gestao-sites/imagens/tipo-imagem',
    );
    setImagesType(response.data);
  }, []);

  // get hotels
  const getHotels = useCallback(async () => {
    const response = await ApiClient.get('/api/v1/gestao-sites/hotel');
    setHotels(response.data);
  }, []);

  // get unities
  const getUnits = useCallback(async () => {
    const response = await ApiClient.get('/api/v1/gestao-sites/unidade');
    setUnits(response.data);
  }, []);

  // get values image edit
  const getValuesImagesEdit = useCallback(async () => {
    const response = await ApiClient.get(
      `/api/v1/gestao-sites/imagens/${type}/${id}/imagem`,
    );

    setValue('imageType', response.data.tipoImagem.id);
    type === 'hotel' && setValue('hotel', response.data.idHotel);
    type === 'unidade' && setValue('unidade', response.data.idUnidade);

    if (response.data.redirecionavel === 's') {
      setIsRedirected(true);
      setValue('checkin', response.data.checkin);
      setValue('checkout', response.data.checkout);
      setValue('qtdAdultos', response.data.qtdAdultos);
      setValue('qtdCriancas', response.data.qtdCriancas);
      setValue('qtdQuartos', response.data.qtdQuartos);
    }

    setValuesToEdit(response.data);
  }, [id, setValue, type]);

  // update image values
  const onSubmit = useCallback(
    async (data) => {
      if (imageOffer.length === 0 && valuesImageEdit?.imagem === '\\x') {
        toast.warning('Você precisa selecionar uma imagem!');
        return;
      }

      let finalImage = null;
      let finalNameImage = '';
      if (imageOffer.length > 0) {
        const resizeFile = (file: any) =>
          new Promise((resolve) => {
            Resizer.imageFileResizer(
              file,
              1920,
              1080,
              'JPEG',
              70,
              0,
              (uri) => {
                resolve(uri);
              },
              'base64',
            );
          });

        finalImage = await resizeFile(imageOffer[0]['file']);
        finalNameImage = imageOffer[0]['file']['name'];
      } else {
        finalImage = valuesImageEdit?.imagem;
        finalNameImage = valuesImageEdit?.nome;
      }

      let valuesToStore: any = {
        id: valuesImageEdit?.id,
        nome: finalNameImage,
        url: 'url',
        imagem: finalImage,
        redirecionavel: isRedirected ? 's' : 'n',
        idTipoImagem: data.imageType,
      };

      data.imageFor === 'hotel'
        ? (valuesToStore.idHotel = data.hotel)
        : data.imageFor === 'unidade'
        ? (valuesToStore.idUnidade = data.unidade)
        : (valuesToStore.idRede = '7da2d7e0-f728-4af0-9a5f-5d7a1013810c');

      if (isRedirected) {
        valuesToStore.checkin = data.checkin;
        valuesToStore.checkout = data.checkout;
        valuesToStore.qtdAdultos = parseInt(data.qtdAdultos);
        valuesToStore.qtdCriancas = parseInt(data.qtdCriancas);
        valuesToStore.qtdQuartos = parseInt(data.qtdQuartos);
      }

      await ApiClient.put(
        `/api/v1/gestao-sites/imagens/${type}`,
        valuesToStore,
      );
      toast.success('Registro atualizado com sucesso!');
      navigate('/dashboard/offer');
      return;
    },
    [
      imageOffer,
      valuesImageEdit?.imagem,
      valuesImageEdit?.id,
      valuesImageEdit?.nome,
      isRedirected,
      type,
      navigate,
    ],
  );

  // get initial values image to update
  useEffect(() => {
    getValuesImagesEdit();
  }, [getValuesImagesEdit]);

  useEffect(() => {
    getImagesType();
    if (watchImageFor === 'unidade') {
      getUnits();
    }
    if (watchImageFor === 'hotel') {
      getHotels();
    }
  }, [getHotels, getImagesType, getUnits, watchImageFor]);

  return (
    <>
      <Typography component="h2" variant="h5">
        Editar promoção
      </Typography>

      <Paper elevation={1} sx={{ p: 3, mt: 3, mb: 3 }}>
        <ImageUploading value={imageOffer} onChange={onChange}>
          {({ onImageUpload, dragProps }) => (
            // write your building UI
            <Grid container spacing={3} marginBottom={3}>
              <Grid item xs={12} md={4} xl={4}>
                <Box
                  minHeight={280}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Button
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={onImageUpload}
                    {...dragProps}
                  >
                    {imageOffer.length <= 0
                      ? 'Selecionar imagem'
                      : 'Alterar imagem'}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12} md={8} xl={8}>
                <Box
                  component="div"
                  sx={{ p: 1, border: '1px dashed grey', overflow: 'hidden' }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  minHeight={280}
                >
                  {imageOffer.length > 0 ? (
                    <img src={imageOffer[0]['dataURL']} alt="" width="100%" />
                  ) : valuesImageEdit && valuesImageEdit.imagem !== '\\x' ? (
                    <img src={valuesImageEdit.imagem} alt="" width="100%" />
                  ) : (
                    <Image />
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </ImageUploading>

        <FormProvider {...methods}>
          <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={3} xl={3}>
                {valuesImageEdit && imagesType.length > 0 && (
                  <InputSelect
                    required={true}
                    id="imagetype-id"
                    name="imageType"
                    label="Tipo de imagem:"
                  >
                    {imagesType.map((imgType: ImagesFormTypes) => (
                      <MenuItem key={imgType.id} value={imgType.id}>
                        {imgType.descricao}
                      </MenuItem>
                    ))}
                  </InputSelect>
                )}
              </Grid>

              <Grid item xs={12} md={3} xl={3}>
                <InputSelect
                  required={true}
                  id="imagefor-id"
                  name="imageFor"
                  label="Imagem para:"
                >
                  <MenuItem value="rede">Rede</MenuItem>
                  <MenuItem value="unidade">Cidade</MenuItem>
                  <MenuItem value="hotel">Hotel</MenuItem>
                </InputSelect>
              </Grid>

              {watchImageFor === 'unidade' && (
                <Grid item xs={12} md={6} xl={6}>
                  {valuesImageEdit && units.length > 0 && (
                    <InputSelect
                      multiple={false}
                      required={true}
                      id="unidade-id"
                      name="unidade"
                      label="Cidade:"
                    >
                      {units.map((unity: UnitsFormTypes) => (
                        <MenuItem key={unity.id} value={unity.id}>
                          {unity.nome}
                        </MenuItem>
                      ))}
                    </InputSelect>
                  )}
                </Grid>
              )}

              {watchImageFor === 'hotel' && (
                <Grid item xs={12} md={6} xl={6}>
                  {valuesImageEdit && hotels.length > 0 && (
                    <InputSelect
                      required={true}
                      id="hotel-id"
                      name="hotel"
                      label="Hotel:"
                    >
                      {hotels.map((hotel: HotelsFormTypes) => (
                        <MenuItem key={hotel.id} value={hotel.id}>
                          {hotel.nome}
                        </MenuItem>
                      ))}
                    </InputSelect>
                  )}
                </Grid>
              )}

              {watchImageFor === 'rede' && (
                <Grid item xs={12} md={6} xl={6}></Grid>
              )}

              <Grid item xs={12} md={12} xl={12}>
                <Typography
                  component="p"
                  sx={{ mt: 3 }}
                  color="primary"
                  fontWeight={800}
                >
                  Imagem redirecionável?
                  <IconButton onClick={() => setIsRedirected(!isRedirected)}>
                    {isRedirected ? (
                      <CheckBox color="primary" />
                    ) : (
                      <CheckBoxOutlineBlank />
                    )}
                  </IconButton>
                </Typography>
              </Grid>

              {isRedirected && (
                <>
                  <Grid item xs={12} md={6} xl={6}>
                    <InputDatepicker name="checkin" label="CheckIn" />
                  </Grid>

                  <Grid item xs={12} md={6} xl={6}>
                    <InputDatepicker
                      name="checkout"
                      label="CheckOut"
                      minDate={new Date(watchCheckIn)}
                    />
                  </Grid>

                  <Grid item xs={12} md={4} xl={4}>
                    <InputSelect
                      required={true}
                      id="qtd-adultos-id"
                      name="qtdAdultos"
                      label="Quantidade de adultos:"
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                    </InputSelect>
                  </Grid>

                  <Grid item xs={12} md={4} xl={4}>
                    <InputSelect
                      required={true}
                      id="qtd-criancas-id"
                      name="qtdCriancas"
                      label="Quantidade de crianças:"
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                    </InputSelect>
                  </Grid>

                  <Grid item xs={12} md={4} xl={4}>
                    <InputSelect
                      required={true}
                      id="qtd-quartos-id"
                      name="qtdQuartos"
                      label="Quantidade de quartos:"
                    >
                      <MenuItem value={1}>1</MenuItem>
                      <MenuItem value={2}>2</MenuItem>
                      <MenuItem value={3}>3</MenuItem>
                      <MenuItem value={4}>4</MenuItem>
                    </InputSelect>
                  </Grid>
                </>
              )}
            </Grid>

            <Button
              startIcon={<Cached />}
              type="submit"
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Atualizar
            </Button>
          </form>
        </FormProvider>
      </Paper>
    </>
  );
};

export default OfferEdit;
