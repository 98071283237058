import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${theme.palette.secondary.main};

  margin: 0 auto;
  padding: 0;

  img.logo-client {
    width: 400px;
  }
  @media only screen and (max-width: 600px) {
    img.logo-client {
      width: 90%;
    }
  }
`;
