import create from 'zustand';

type PopUpProps = {
  isOpen: boolean;
  title?: string;
  description?: string;
};

export const usePopUp = create<PopUpProps>(() => ({
  isOpen: false,
  title: '',
  description: '',
}));
