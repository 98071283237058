import React, { useState, useCallback } from 'react';
import { Select, InputLabel } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl } from '@material-ui/core';

const InputSelect = ({
  name,
  initialValue,
  label,
  id,
  rules,
  children,
  ...props
}: any) => {
  const { control } = useFormContext();

  return (
    <FormControl {...props} fullWidth>
      <InputLabel id={id}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Select
            {...field}
            // defaultValue={initialValue}
            labelId={id}
            id={id}
            label={label}
          >
            {children}
          </Select>
        )}
      />
    </FormControl>
  );
};

export default InputSelect;
