import React, { useState } from 'react';

import {
  IconButton,
  Badge,
  Menu,
  MenuItem,
  Divider,
  Avatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';

import {
  Menu as MenuIcon,
  KeyboardArrowDown,
  Email,
  AccountBox,
  Password,
  Logout,
  ManageSearch,
} from '@mui/icons-material';

// styles
import {
  Container,
  ContainerInside,
  ContainerButtonProfile,
  SignOutContainer,
} from './styles';

// actions
import { useSidebar } from '../sidebar/sidebarActions';

const Header: React.FC = () => {
  const { open } = useSidebar();

  // TOGGLE PROFILE
  const [anchorElProfile, setAnchorElProfile] = useState<null | HTMLElement>(
    null,
  );
  const openProfile = Boolean(anchorElProfile);
  const handleOpenProfile = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElProfile(event.currentTarget);
  };
  const handleCloseProfile = () => {
    setAnchorElProfile(null);
  };
  // TOGGLE PROFILE

  return (
    <>
      <Container>
        <ContainerInside>
          <div>
            <IconButton
              onClick={() => useSidebar.setState({ open: !open })}
              color="secondary"
            >
              <MenuIcon />
            </IconButton>
          </div>

          <div className="container-infos">
            <div>
              <ContainerButtonProfile
                id="profile-button"
                aria-controls="profile-menu"
                aria-haspopup="true"
                aria-expanded={openProfile ? 'true' : undefined}
                onClick={handleOpenProfile}
                endIcon={<KeyboardArrowDown color="secondary" />}
              >
                <p className="user-name">Olá, user</p>
              </ContainerButtonProfile>
              <Menu
                id="basic-menu"
                anchorEl={anchorElProfile}
                open={openProfile}
                onClose={handleCloseProfile}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {/* <MenuItem onClick={() => {}}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <AccountBox />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Meu perfil "
                      secondary="Alterar dados do perfil"
                    />
                  </ListItem>
                </MenuItem> */}

                {/* <MenuItem onClick={() => {}}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar>
                        <Password />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary="Senha "
                      secondary="Alterar senha de acesso"
                    />
                  </ListItem>
                </MenuItem> */}

                {/* <Divider sx={{ my: 0.5 }} /> */}

                <MenuItem
                  onClick={() => {}}
                  // sx={{
                  //   display: 'flex',
                  //   justifyContent: 'center',
                  //   alignCenter: 'center',
                  // }}
                >
                  <SignOutContainer>
                    <Logout /> Sair do sistema
                  </SignOutContainer>
                </MenuItem>
              </Menu>
            </div>
          </div>
        </ContainerInside>
      </Container>
    </>
  );
};

export default Header;
