import { useState, useEffect, useCallback } from 'react';
import { Box, Button, Typography, Paper } from '@mui/material';
import { FormProvider, useForm, SubmitHandler } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

// components
import { InputText } from '../../components';

// styles
import { Container } from './styles';

// images
import logo from '../../assets/imgs/logo.svg';

import { LoginFormTypes } from './../../../domain/types';

import { ApiClient } from '../../../infra';

const LoginPage = () => {
  let navigate = useNavigate();
  let { idHotel } = useParams();

  const [bookingData, setBookingData] = useState<any>(null);

  const defaultValues = {
    login: 'anahelena@exemplo.com',
    password: '12345678',
  };
  const methods = useForm({
    defaultValues,
    shouldFocusError: true,
    mode: 'onChange',
  });

  const { handleSubmit } = methods;

  // const loadBookingNetwork = useCallback(async () => {
  //   let idHotelCheck = idHotel ?? 'vega-hotel-curitiba';
  //   const responseBooking = await ApiClient.get(
  //     `/api/v1/unidade/rota/${idHotelCheck}`,
  //   );
  //   setBookingData(responseBooking.data);
  // }, [idHotel]);

  // useEffect(() => {
  //   loadBookingNetwork();
  // }, []);

  // const generateToken = async (userInfo: any) => {
  //   let client_id = process.env.REACT_APP_CLIENT_ID_VEGAS
  //     ? process.env.REACT_APP_CLIENT_ID_VEGAS
  //     : "";

  //   const formData = new URLSearchParams();

  //   formData.append("username", userInfo.login);
  //   formData.append("password", userInfo.senha);
  //   formData.append("client_id", client_id);
  //   formData.append("grant_type", "password");

  //   await ApiClient.post(`/api/v1/auth/token/hospede/web`, formData, {
  //     headers: {
  //       "Content-Type": "application/x-www-form-urlencoded",
  //       client_id: client_id,
  //       // codigoRede: bookingNetwork.idRede,
  //       // codigoUnidade: bookingNetwork.idUnidadeRede,
  //     },
  //   })
  //     .then((resp) => {
  //       // saveBookingToken(resp);
  //       // navigate("/home");
  //     })
  //     .catch(function (error) {
  //       HandleError(error);
  //     });
  // };

  const onSubmit: SubmitHandler<LoginFormTypes> = async (data) => {
    navigate('dashboard/offer');
    return;
  };

  //   await ApiClient.post(`api/v1/hospede/web`, userCredentials, {
  //     headers: {
  //       codigoRede: bookingNetwork.idRede,
  //       codigoUnidade: bookingNetwork.idUnidadeRede,
  //     },
  //   })
  //     .then((resp) => {
  //       saveSessionUserCredentials(resp);
  //       generateToken(resp.data);
  //     })
  //     .catch(function (error) {
  //       HandleError(error);
  //     });
  // };

  return (
    <Container>
      {bookingData && bookingData?.marca ? (
        <img src={bookingData?.marca} alt="" className="logo-client" />
      ) : (
        <img src={logo} alt="Vega" className="logo-client" />
      )}

      <Typography variant="h6" align="center" fontWeight={700}>
        Iniciar checkout em VEGA Hotel
      </Typography>

      <Paper
        elevation={3}
        style={{ padding: 28, margin: 12, borderRadius: 12 }}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <InputText
              sx={{ mb: 2 }}
              label="Login"
              name="login"
              variant="outlined"
              type="numero"
              placeHolder="anahelena@exemplo.com"
              rules={{
                required: {
                  value: true,
                  message: 'O campo login é obrigatório',
                },
              }}
              required
            />

            <InputText
              sx={{ mb: 2 }}
              label="Senha"
              name="password"
              variant="outlined"
              type="password"
              rules={{
                required: {
                  message: 'O campo senha é obrigatório',
                },
              }}
              required
            />

            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography>
                Utilize o login e senha fornecidos para acesso à internet
              </Typography>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
              >
                Fazer login
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Paper>

      {/* <img src={logo} alt="Vega" /> */}
    </Container>
  );
};

export default LoginPage;
