import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

type Props = TextFieldProps & {
  label: string;
  name: string;
  errorMessage?: string;
  variant: string;
  rules: object;
  required?: boolean;
  style?: React.CSSProperties;
  defaultValue?: object | null;
  disabled?: boolean;
  type?: string;
  maxLength?: number | string;
  placeHolder?: string;
};

const InputText = ({
  label,
  name,
  errorMessage,
  variant,
  rules,
  required,
  style,
  defaultValue,
  disabled,
  type,
  maxLength,
  placeHolder,
  ...rest
}: Props) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue || ''}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { error },
      }) => (
        <TextField
          type={type}
          error={!!error}
          onChange={onChange}
          onBlur={onBlur}
          fullWidth
          value={value || ''}
          label={label}
          disabled={disabled}
          variant={variant}
          helperText={error && errorMessage ? errorMessage : ''}
          required={required}
          placeholder={placeHolder}
          style={style}
          {...rest}
        />
      )}
    />
  );
};

export default InputText;
