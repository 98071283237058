import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ptBR from 'date-fns/locale/pt-BR';
import { FormControl, TextField } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider, DatePicker, StaticDatePicker } from '@mui/lab';

// styles
import { ContainerDatePicker } from './styles';

const InputDatepicker = ({ name, label, minDate = null, ...props }: any) => {
  const { control } = useFormContext();
  return (
    <FormControl {...props} fullWidth>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <ContainerDatePicker>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
              <StaticDatePicker
                className="datepicker"
                toolbarTitle={label}
                // showToolbar={false}
                orientation="portrait"
                disablePast
                label={label}
                inputFormat="d/MM/Y"
                openTo="day"
                value={value}
                onChange={onChange}
                minDate={minDate && minDate}
                renderInput={(params) => (
                  <TextField {...params} fullWidth sx={{ width: '100%' }} />
                )}
              />
            </LocalizationProvider>
          </ContainerDatePicker>
        )}
      />
    </FormControl>
  );
};

export default InputDatepicker;
