import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { Button, Typography } from '@material-ui/core';

// styles
import { useStyles } from './popUp-styles';

import { usePopUp } from './popUpActions';

export const PopUp = () => {
  const { isOpen, title, description } = usePopUp();
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      closeAfterTransition={false}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClose={() => usePopUp.setState({ isOpen: false })}
    >
      <div className={classes.paper}>
        <h2 id="transition-modal-title">{title}</h2>
        <Typography variant="body2" id="transition-modal-description">
          {description}
        </Typography>
        <Button
          type="button"
          variant="contained"
          color="primary"
          size="large"
          style={{
            width: '100%',
            textTransform: 'none',
          }}
          onClick={() => usePopUp.setState({ isOpen: false })}
        >
          Fechar
        </Button>
      </div>
    </Modal>
  );
};

export default PopUp;
