import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// RuoterMain
import { RouterMain } from '../routes';

// material ui theme
import { theme } from '../presentation/styles/theme';

// components
import { Loading, PopUp } from '../presentation/components';

function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Loading />
        <PopUp />
        <ToastContainer autoClose={6000} />
        <RouterMain />
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
