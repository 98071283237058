import create from 'zustand';

type SidebarProps = {
  open: boolean;
  width: number;
};

const { innerWidth: width } = window;
export const useSidebar = create(
  () =>
    ({
      open: width > 720,
      width: width,
    } as SidebarProps),
);
