import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

import { Box, Button, Typography } from '@material-ui/core';
import { useStyles } from './modal-styles';

type ModalProps = {
  open: boolean;
  title: string;
  toggleModal: () => void;
  children: any;
};

export function ModalMessage({
  open = false,
  title,
  toggleModal,
  children,
}: ModalProps) {
  const classes = useStyles();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition={false}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClose={() => {
        toggleModal();
      }}
    >
      <div className={classes.paper}>
        <h2 id="transition-modal-title">{title}</h2>
        <Typography variant="body2">
          Tem certeza que deseja excluir este registro?
        </Typography>

        <Box>{children}</Box>

        <Button
          type="button"
          variant={'contained'}
          color="primary"
          size="large"
          style={{
            width: '100%',
            textTransform: 'none',
          }}
          onClick={() => {
            toggleModal();
          }}
        >
          Fechar
        </Button>
      </div>
    </Modal>
  );
}

export default ModalMessage;
