import styled from 'styled-components';
import { Button } from '@mui/material';
import { theme } from '../../styles/theme';

export const Container = styled.header`
  width: 100%;
  margin: 0 auto;
`;

export const ContainerInside = styled.div`
  width: 100%;
  background-color: ${theme.palette.primary.main};
  height: 68px;
  padding: 4px 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  .container-infos {
    display: flex;
    align-items: center;
    height: 100%;

    p.user-name {
      margin: 0;
      text-transform: capitalize;
      color: ${theme.palette.common.white};
      font-weight: 700;
    }

    .MuiSvgIcon-root {
      font-size: 28px;
    }
  }
`;

export const ContainerButtonProfile: typeof Button = styled(Button)`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > .MuiAvatar-root {
    width: 28px;
    height: 28px;
    margin-right: 8px;
    background-color: ${theme.palette.secondary.light};
  }
  p.user-name {
    text-transform: capitalize;
  }
`;

export const SignOutContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${theme.palette.primary.main};

  .MuiSvgIcon-root {
    margin-right: 8px;
    font-size: 16px;
  }
`;
