import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";

import { Box, Button, Typography } from "@material-ui/core";
import { useStyles } from "./modal-success-styles";

import DraftIcon from "@material-ui/icons/Drafts";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import PhoneInTalkIcon from "@material-ui/icons/PhoneInTalk";

type Props = {
  open: boolean;
  title: string;
  handleCloseModal: () => void;
};

export function ModalMessage({
  open = false,
  title = "",
  handleCloseModal,
}: Props) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      closeAfterTransition={false}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      onClose={() => {
        handleCloseModal();
      }}
    >
      <div className={classes.paper}>
        <h2 id="transition-modal-title">Seu pagamento foi aprovado!</h2>
        <Box marginTop="0.1rem">
          <Typography variant="body1">
            Você receberá a nota fiscal no e-mail informado no seu cadastro.
          </Typography>
          <Typography variant="body1">
            Dicas sobre o recebimento da NF
          </Typography>
        </Box>

        <Box className={classes.total}>
          <Box>
            <DraftIcon className={classes.icon} />
            <Typography variant="subtitle1">
              Mantenha seu e-mail sempre atualizado em nosso cadastro, é para
              este email que enviamos a sua Nota Fiscal.
            </Typography>
          </Box>
          <Box margin="1rem 0">
            <AlternateEmailIcon className={classes.icon} />
            <Typography variant="subtitle1">
              O email é enviado pelo remetente da prefeitura do município em que
              você se hospedou, fique atento.
            </Typography>
          </Box>
          <Box margin="1rem 0">
            <AccountBalanceIcon className={classes.icon} />
            <Typography variant="subtitle1">
              Cheque seu spam e lixeira, talvez sua nota esteja lá.
            </Typography>
          </Box>
          <Box margin="1rem 0">
            <PhoneInTalkIcon className={classes.icon} />
            <Typography variant="subtitle1">
              Se mesmo assim não encontrar nos ligue que reenviamos para você -
              (11) 2627-3200
            </Typography>
          </Box>
        </Box>
        <Button
          type="button"
          variant={"contained"}
          color="primary"
          size="large"
          style={{
            width: "100%",
            textTransform: "none",
            marginTop: "0.5rem",
          }}
          onClick={() => {
            handleCloseModal();
          }}
        >
          Fechar
        </Button>
      </div>
    </Modal>
  );
}

export default ModalMessage;
