import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ListItemIcon,
  IconButton,
  Divider,
  ListItemButton,
  ListItemText,
  Collapse,
  List,
  Icon,
} from '@mui/material';

import {
  MenuOpen,
  ExpandLess,
  ExpandMore,
  ChevronRight,
  Image,
} from '@mui/icons-material';

// image
import logoCompany from '../../assets/imgs/logo.svg';

// styles
import { Container, BackgroundMobile, ButtonCloseSidebar } from './styles';

// actions
import { useSidebar } from './sidebarActions';

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const { open, width } = useSidebar();

  return (
    <>
      {open && width < 720 && <BackgroundMobile />}

      {open && width < 720 && (
        <ButtonCloseSidebar>
          <IconButton onClick={() => useSidebar.setState({ open: false })}>
            <MenuOpen />
          </IconButton>
        </ButtonCloseSidebar>
      )}

      <Container openMenu={open}>
        <img src={logoCompany} alt="" className="logo-company" />
        <Divider />
        <List key={0}>
          <ListItemButton>
            <ListItemIcon>
              <Image color="primary" />
            </ListItemIcon>
            <ListItemText primary="Promoções" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('offer')}>
            <ListItemIcon>
              <ChevronRight />
            </ListItemIcon>
            <ListItemText primary="Listar" />
          </ListItemButton>

          <ListItemButton
            sx={{ pl: 4 }}
            onClick={() => navigate('offer/create')}
          >
            <ListItemIcon>
              <ChevronRight />
            </ListItemIcon>
            <ListItemText primary="Cadastrar" />
          </ListItemButton>
        </List>
      </Container>
    </>
  );
};

export default Sidebar;
