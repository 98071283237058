import { HTMLAttributes } from 'react';
import styled from 'styled-components';
import { theme } from '../../styles/theme';

type ContainerProps = HTMLAttributes<HTMLHeadingElement> & {
  openMenu: keyof JSX.IntrinsicElements | number | boolean;
};

export const Container = styled.nav<ContainerProps>`
  width: ${(props) => (props.openMenu ? '240px' : 0)};
  height: 100vh;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0 3px 12px;
  margin: 0;
  padding: 24px 0;
  transition: all 0.6s ease;
  overflow-y: auto;

  img.logo-company {
    display: flex;
    margin: 0 auto 24px auto;
    width: 50%;
  }

  div.subItemMenu {
    margin: 0;
    background-color: #f4f4f4;
    overflow: hidden;
  }

  .MuiListItemButton-root {
    background-color: #f4f4f4;
    display: ${(props) => (props.openMenu ? 'flex' : 'none')};
  }
  .MuiTypography-root {
    font-size: 14px;
  }

  @media only screen and (max-width: 720px) {
    & {
      top: 0;
      left: 0;
      border-radius: 0;
      position: fixed;
      z-index: 999;
      transition: all 0.4s ease;
    }
  }
`;

export const BackgroundMobile = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: block;
`;

export const ButtonCloseSidebar = styled.div`
  display: none;
  transition: all 0.6s ease;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: ${theme.palette.primary.main};
  border-radius: 50%;

  .MuiSvgIcon-root {
    color: white;
  }

  @media only screen and (max-width: 720px) {
    & {
      display: inherit;
      left: 230px;
      top: 24px;
    }
  }
`;
