import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Grid, MenuItem } from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

// api
import { ApiClient } from '../../../infra';

// components
import { DataTableMui, InputSelect, Modal } from '../../components';

// types
import { HotelsFormTypes, UnitsFormTypes } from '../../../domain/types';

const Offer = () => {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [valueToDelete, setValueToDelete] = useState('');

  const [hotels, setHotels] = useState<Array<HotelsFormTypes>>([]);
  const [units, setUnits] = useState<Array<UnitsFormTypes>>([]);
  const [dataOffers, setDataOffers] = useState([]);

  const defaultValues = {
    imageFor: 'rede',
    unidade: '',
    hotel: '',
  };
  const methods = useForm({
    defaultValues,
    shouldFocusError: true,
    mode: 'onChange',
  });

  const { watch, setValue } = methods;

  const watchImageFor = watch('imageFor');
  const watchHotel = watch('hotel');
  const watchUnity = watch('unidade');

  const openModalDelete = (id: string) => {
    setOpenModal(true);
    setValueToDelete(id);
  };

  const handleDelete = useCallback(async () => {
    const values: any = {
      IdImagem: valueToDelete,
    };
    await ApiClient.delete(
      `/api/v1/gestao-sites/imagens/${watchImageFor}`,
      values,
    );
    setOpenModal(false);
    toast.success('Cadastro removido com sucesso!');
  }, [valueToDelete, watchImageFor]);

  const columns = [
    {
      name: 'imagem',
      label: 'Imagem',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value: string) => (
          <img src={value} alt="" width="150" />
        ),
      },
    },
    {
      name: 'nome',
      label: 'Nome da imagem',
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: 'tipoImagem',
    //   label: 'Sigla',
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value: { sigla: string; descricao: string }) => (
    //       <Typography component="p">{value.sigla}</Typography>
    //     ),
    //   },
    // },
    {
      name: 'url',
      label: 'URL',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'urlPromocional',
      label: 'URL Promocional',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'id',
      label: 'Ações',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (id: string) => (
          <>
            <Button
              size="small"
              onClick={() => navigate(`edit/${id}/${watchImageFor}`)}
              variant="contained"
              color="warning"
              sx={{ m: 1 }}
            >
              <Edit sx={{ color: 'common.white', fontSize: 16 }} />
            </Button>
            <Button
              size="small"
              onClick={() => openModalDelete(id)}
              variant="contained"
              color="error"
              sx={{ m: 1 }}
            >
              <Delete sx={{ color: 'common.white', fontSize: 16 }} />
            </Button>
          </>
        ),
      },
    },
  ];

  // get hotels
  const getHotels = useCallback(async () => {
    const response = await ApiClient.get('/api/v1/gestao-sites/hotel');
    setHotels(response.data);
    setValue('hotel', response.data[0].id);
  }, [setValue]);

  // get unities
  const getUnits = useCallback(async () => {
    const response = await ApiClient.get('/api/v1/gestao-sites/unidade');
    setUnits(response.data);
    setValue('unidade', response.data[0].id);
  }, [setValue]);

  // get offers
  const getOffers = useCallback(async () => {
    const id =
      watchImageFor === 'rede'
        ? '7da2d7e0-f728-4af0-9a5f-5d7a1013810c'
        : watchImageFor === 'hotel'
        ? watchHotel
        : watchUnity;
    if (id.length !== 0) {
      const responseOffers = await ApiClient.get(
        `/api/v1/gestao-sites/imagens/${watchImageFor}/${id}`,
      );
      setDataOffers(responseOffers.data);
    }
  }, [watchHotel, watchImageFor, watchUnity]);

  // initial getHotels | getUnits
  useEffect(() => {
    if (watchImageFor === 'unidade') {
      getUnits();
    }
    if (watchImageFor === 'hotel') {
      getHotels();
    }
  }, [getHotels, getUnits, watchImageFor]);

  // initial getOffers
  useEffect(() => {
    getOffers();
  }, [getOffers]);

  return (
    <>
      <Modal
        title="Deletar Registro"
        open={openModal}
        toggleModal={() => setOpenModal(!openModal)}
      >
        <Button
          variant="contained"
          color="error"
          size="large"
          style={{
            textTransform: 'none',
            margin: '12px',
          }}
          onClick={() => handleDelete()}
        >
          Sim, remover
        </Button>
      </Modal>
      <Box mb={3}>
        <Button
          onClick={() => navigate('create')}
          startIcon={<Add sx={{ color: 'common.white' }} />}
          variant="contained"
          color="success"
        >
          <Typography component="p" color="common.white" fontWeight={800}>
            Cadastrar nova promoção
          </Typography>
        </Button>
      </Box>

      <Grid container spacing={3} marginBottom={3}>
        <FormProvider {...methods}>
          <Grid item xs={12} md={6} xl={6}>
            <InputSelect
              required={true}
              id="imagefor-id"
              name="imageFor"
              label="Filtrar lista para:"
            >
              <MenuItem value="rede">Rede</MenuItem>
              <MenuItem value="hotel">Hotel</MenuItem>
              <MenuItem value="unidade">Cidade</MenuItem>
            </InputSelect>
          </Grid>

          {watchImageFor === 'hotel' && (
            <Grid item xs={12} md={6} xl={6}>
              <InputSelect
                required={true}
                id="hotel-id"
                name="hotel"
                label="Selecione um hotel:"
              >
                {hotels &&
                  hotels.map((hotel: HotelsFormTypes) => (
                    <MenuItem key={hotel.id} value={hotel.id}>
                      {hotel.nome}
                    </MenuItem>
                  ))}
              </InputSelect>
            </Grid>
          )}

          {watchImageFor === 'unidade' && (
            <Grid item xs={12} md={6} xl={6}>
              <InputSelect
                multiple={false}
                required={true}
                id="unidade-id"
                name="unidade"
                label="Selecione uma cidade:"
              >
                {units &&
                  units.map((unity: UnitsFormTypes) => (
                    <MenuItem key={unity.id} value={unity.id}>
                      {unity.nome}
                    </MenuItem>
                  ))}
              </InputSelect>
            </Grid>
          )}

          {watchImageFor === 'rede' && <Grid item xs={12} md={6} xl={6}></Grid>}
        </FormProvider>
      </Grid>

      <DataTableMui
        title="Lista de images cadastradas"
        columns={columns}
        values={dataOffers}
        pagination={false}
      />
    </>
  );
};

export default Offer;
