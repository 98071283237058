import axios from "axios";
import { useLoaderScreen } from "../../../presentation/components/loading/loadingActions";
import { usePopUp } from "../../../presentation/components/popUp/popUpActions";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACK_END_URL,
  // responseType: 'json',
  // withCredentials: false,
});

// const api = axios.create();

api.interceptors.request.use(
  (config) => {
    useLoaderScreen.setState({ open: true });
    return config;
  },
  (error) => {
    useLoaderScreen.setState({ open: false });
    usePopUp.setState({
      isOpen: true,
      title: ` Erro Response - ${String(error.response.status)}`,
      description: String(
        error.response.data.Message
          ? error.response.data.Message
          : error.response.data
      ),
    });
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    useLoaderScreen.setState({ open: false });
    return response;
  },
  (error) => {
    useLoaderScreen.setState({ open: false });

    usePopUp.setState({
      isOpen: true,
      title: ` Erro Response - ${String(error.response.status)}`,
      description: String(
        error.response.data.Message
          ? error.response.data.Message
          : error.response.data
      ),
    });

    return Promise.reject(error);
  }
);

export default api;
