import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  main: {
    maxHeight: "40rem",
    width: "20rem",
    minWidth: "320px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "1rem",
  },
  total: {
    maxHeight: "40rem",
    width: "20rem",
    minWidth: "320px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "1rem",
  },
  paper: {
    background: "#FFFFFF",
    border: "1px solid #C7C7C7",
    borderRadius: "10px",
    width: "90%",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "90%",
    },
    maxWidth: "375px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "2.5rem",
    textAlign: "center",
    "& h2": {
      fontSize: "1.5rem",
      lineHeight: "133%",
      fontWeight: "700",
      color: "var(--primary)",
      margin: "0",
    },
    "& p": {
      margin: "1.32rem 0 2rem 0",
    },
  },
}));
