import { Routes, Route } from 'react-router-dom';

//pages
import {
  LoginPage,
  LayoutAdmin,
  Offer,
  OfferCreate,
  OfferEdit,
} from '../presentation/pages';

export const RouterMain = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route path="/:idHotel" element={<LoginPage />} />

      {/* PRIVATE ROUTES */}
      <Route path="/dashboard" element={<LayoutAdmin />}>
        <Route path="offer" element={<Offer />} />
        <Route path="offer/create" element={<OfferCreate />} />
        <Route path="offer/edit/:id/:type" element={<OfferEdit />} />
      </Route>
    </Routes>
  );
};
