import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// styles
import { ContainerLayout } from './styles';

// components
import { Sidebar, Header } from '../../components';

const LayoutAdmin = () => {
  const auth = true;

  return !auth ? (
    <Navigate to="/" replace />
  ) : (
    <ContainerLayout>
      <ContainerLayout>
        <div className="inside">
          <aside>
            <Sidebar />
          </aside>
          <main className="main">
            <section className="container-header">
              <Header />
            </section>
            <section className="main-outlet">
              <Outlet />
            </section>
          </main>
        </div>
      </ContainerLayout>
    </ContainerLayout>
  );
};

export default LayoutAdmin;
