import styled from 'styled-components';
import { theme } from '../../styles/theme';

export const ContainerLayout = styled.div`
  margin: 0;
  padding: 0;
  background-color: ${theme.palette.secondary.main};
  min-height: 100vh;
  box-sizing: border-box;

  .inside {
    display: flex;
    justify-content: flex-start;
  }
  .container-header {
    margin-bottom: 0;
  }

  .main {
    margin: 0 auto;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
  }

  .main-outlet {
    margin: 0;
    padding: 24px;
    overflow-x: hidden;
  }
`;
